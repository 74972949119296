
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font-Awesome
$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;
@import 'node_modules/font-awesome/scss/font-awesome.scss';
